import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import HowItWorks from "../components/howitworks";
import SkillSet from "../components/skillset";
import WhyUs from "../components/why";
import CTA from "../components/cta";

const CSPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(
        fluid: { originalName: { eq: "customer-service-bg.jpg" } }
      ) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      mobile: imageSharp(fluid: { originalName: { eq: "cs-exec.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      web: imageSharp(
        fluid: { originalName: { eq: "service-desk-exec.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      backend: imageSharp(
        fluid: { originalName: { eq: "call-center-officer.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      others: imageSharp(fluid: { originalName: { eq: "cs-others.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroTitle = (
    <>
      Hire <em className="font-weight-light">Good</em> Customer Service
      Executives
    </>
  );

  const skills = {
    title: "Finding the right skillset",
    sets: [
      {
        title: "Customer Service Executives",
        image: data.mobile.fluid,
        list: [
          "Respond promptly",
          "Handle and resolve customer inquiries & complaints"
        ]
      },
      {
        title: "Service Desk Executives",
        image: data.web.fluid,
        list: ["Provide record-keeping of traceability and data entry"]
      },
      {
        title: "Call Centre Officers",
        image: data.backend.fluid,
        list: [
          "Assist customers in resolving issues with purchased products or services"
        ]
      },
      {
        title: "Others",
        image: data.others.fluid,
        list: [
          "Provide customers with friendly and professional service during call transactions"
        ]
      }
    ]
  };

  const whyUs = {
    title: (
      <>
        <span className="font-weight-normal">Why</span> Choose Us
      </>
    ),
    sets: [
      {
        title: "Dedicated Customer Service Executives",
        desc:
          "Every customer service executives on Tech2Reach is a full-time " +
          "worker. This means they have proven abilities, their time is well-managed, " +
          "and they are dedicated to your company – and your company only."
      },
      {
        title: "Unique talent pool",
        desc:
          "By allowing you to interview and select talent directly, " +
          "Tech2Reach ensures you talented customer service are not freelancers."
      },
      {
        title: "Thorough vetting process",
        desc:
          "Every talent on Tech2Reach is handpicked for quality and talent. " +
          "Forget choosing between freelancers – Tech2Reach shows you " +
          "high-quality, verified talent, every time."
      },
      {
        title: "Scale at will",
        desc:
          "Tech2Reach’s model is fine-tuned for quickly growing businesses " +
          "who need longer-term dedicated tech solutions. Leverage the HR and " +
          "management expertise of managing digital marketers and save yourself " +
          "time. Add or subtract digital marketers to your team quickly."
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title="Customer Service Executives"
        pathname="/customer-service/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "customer",
          "service",
          "executives"
        ]}
      />
      <Hero
        background={data.hero.fluid}
        title={heroTitle}
        subTitle="Getting talents couldn't be any easier than this"
        buttonText="Get In Touch"
      />
      <HowItWorks
        title1="No hiring limits"
        desc1="Engage as many overseas talents as you want. Building an offshore call centre team at a much lower cost."
        title2="Cost savings"
        desc2="Up to 40% cost saving engaging offshore customer service executives as compared to hiring a local"
        title3="Dedicated talents"
        desc3="A staff fully dedicated to you. Not a freelancer. Doesn't handle other company's projects."
        title4="Don't sweat it"
        desc4="We manage and comply with the overseas regulations. Payroll, taxation, legal compliance, office space, welfare and all other human resource aspects are all taken care of."
      />
      <SkillSet skills={skills} />
      <WhyUs why={whyUs} />
      <CTA />
    </Layout>
  );
};

export default CSPage;
